/*
    https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

    https://react-bootstrap.github.io/components/alerts/
*/

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

/* ---------- Variables - bootstrap ---------- */



//$body-bg: #474853;
//$body-color:yellow;
//$primary:yellow;
//$text-color:yellow;
//$theme-colors: (
//    primary: $primary
//);

$navbar-dark-color:#AAA0A0;

$accent1:#474E68;
$accent2: gray;// #6B728E;
$accent3:#8E8268;
$accent4:white; /*#FEF9C7;*/
$accent5:#1e2226;

$modal-accent1: #8D8741;
$modal-accent2: #414141;//#6B728E;
$modal-accent3: #DAAD86;
$modal-accent4: #BC986A;
$modal-accent5: #FBEEC1;
/* ---------- Variables - app ---------- */
$app-navbar-height:90px;



/* ---------- Media queries up ---------- */

@include media-breakpoint-up(xs) {
 
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {
 
}

@include media-breakpoint-up(lg) {
    .nav-icon {
        display: none;
    }
}

@include media-breakpoint-up(xl) {
 
}

/* ---------- Media queries down ---------- */

@include media-breakpoint-down(xs) {
}

@include media-breakpoint-down(sm) {
    
}

@include media-breakpoint-down(md) {
    .nav-link {
        margin-right: auto;
    }
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(xl) {
}

/* ---------- App level ---------- */
* {
    margin:0;
    padding:0;
}

a {
    color:$accent4 !important;
}

.content-root {
    
    margin-top:$app-navbar-height;
    min-height:100vh;
}
.center-content {
    display:flex;
    justify-content:center;
    align-items:center;
}
.container-fluid #banner-image {
    padding-left:0px;
    padding-right:0px;

}
#banner-image {
    width:100%;
    height:auto;
}

.light-bg {
    background-color:$accent4;
    color:$accent5;

}

.dark-bg {
    background-color: $accent5;
    color: $accent3;
}
.medium-bg {
    background-color:$accent3;
    color:$accent5;
}

.pageBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $accent4;
}

.colorSet1 {
    background-color: $accent4;
    color: $accent5;
}
.darkLink  {
    color:$accent2 !important;
}
/*--------------------------------------------------------------
# Brand
--------------------------------------------------------------*/

.logo-container {
    margin-top: 4px;
    margin-left: 4px;
}

.logo-small {
    font-size: 1vw;
}

.logo, .logo-small {
    font-family: SansMan;
    color: $accent3 !important;
}

.logo:hover {
    color: whitesmoke !important;
}



/* ---------- Nav bar ---------- */

.nav-bg {
    background-color: $body-bg;
}

.nav-icon {
    margin-right:8px;
    font-size:18pt;
}
.logo-large {
    width: clamp(80px,12vw,200px);
    height: auto;
    margin-left:8px;
}

.nav-container {
    border-bottom: solid 3px black;
    min-height: $app-navbar-height;
}

#profileButton {
    background-color: transparent;
}

.dropdown-menu {
    background-color: $accent4 !important;
    padding: 8px !important;

    a {
        color: $accent5 !important;
    }
}

.dropdown-menu a:hover {
        background-color: $accent5 !important;
        color: $accent4 !important;
}

.nav-link {
    color: gray !important;
}

.nav-link.active {
    color:$accent4 !important;

}

.nav-link:hover {
    color: $accent4 !important;
}

/* ---------- Home ---------- */
.info-box {
    align-self: center;
    border-top: 2px solid $accent2;
    display: flex;
    flex-direction: column;
    min-width: 400px;
}

.info-box-icon {
    color: $accent2;
    align-self:center;
}

.info-box-header, .info-box-title {

    color:$accent2;
}
.info-box-header {
    text-align: center;
}
.info-box-title {
    text-align:left;
    width:clamp(100px,10vw,400px);
}

.info-box-text {
    text-align:left;
    width:100%;
    color:$accent3;
}

.info-box-grid {
    display:flex;
    flex-direction:row;
}

.info-box-text ul {
    list-style-type: none;
}
.info-box-grid svg {
    margin-right:8px;
    margin-top:10px;
}

.info-box-grid p {
 
}

#number_jumble {
    width: 25vw;
    height: 100%;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

@include media-breakpoint-down(md) {

    .info-box {
        min-width: 300px;
    }
    
}

/* --------------------------------------- 
# Subscription Types
--------------------------------------- */
#subPlanTypes {
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding-bottom:2vw;
}
.subPlanType {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    border: solid 5px black;
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    padding-bottom: 2vw;
}
#banner {
    display: flex;
    flex-direction: column;
    align-content:center;
    justify-content:center;
}



#bannerObjects {
    display:flex;
    flex-direction:row;
    justify-content:space-around;
}
.bannerObject {
    border-top:solid 1px black;
    margin:2vw;
    padding:1vw;
}

.subPlanType:hover {
    border: solid 5px $accent1;
}

.acceptableUse, .nonAcceptableUse {
    margin-top: 2vh;
    color: $accent5;
}

.acceptableUse ul, .nonAcceptableUse ul {
    margin-left: clamp(20px,3vw,35px);
}

.acceptableUse {
    background-color:lightgreen;
}
.nonAcceptableUse {
    background-color:lightcoral;
}

.subTypeText {
    width:30vw;
    min-width:250px;
    align-self:center;
    justify-self:center;
}
#freeTrialImg {
    float:right;
    width:15vw;
    min-width:150px;
}

#nonBusinessFreeText {
 background-color:$accent4;
 
}
.iconButton {
    align-self: center;

    div {
        padding-top: .5vw;
        padding-bottom: .5vw;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
    }

    svg {
        margin-left: 6px;
    }
}

@include media-breakpoint-down(md) {

    #subPlanTypes {
        flex-direction: column;
    }
}



/* --------------------------------------- 
# Subscription plans
--------------------------------------- */

#subPlansTopContainer {
    display: flex;
    flex-direction: row;
    background-image: url(./Assets/blue-neural.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    background-position:top left;
    background-clip:border-box;
}

#subPlansLeft {

}

#subPlansRight {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items:center;
}

#fourGuysImg {
    width:30vw;
    min-width:250px;
}
.disclosureCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    opacity: 0.8;

    p > svg {
        margin-right: 8px;
    }
}

.disclosureCardHeader {
    color:$accent2;
    display:flex;
    align-self:center;
    flex-direction:column;
    align-items:center;
}

#freeSubContainer {
    
    border:yellow 6px dashed;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    svg {
        color:yellow;
    }
}

#promoCodes {
    background-color: $accent4;
    color: $accent5;
    border: solid 1px black;

    input {
        margin-left: 8px;
        width: 200px;
    }
}

.sub-plan-grid {
    display: flex;
    flex-direction: column;
}

.gridrow, .gridheaderrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left:15px;
    border: solid 1px transparent;

}

.cell-sub {width:10%;}
.cell-desc {width:50%;}
.cell-cost {width:10%;}
.cell-dur {width:10%;}
.cell-total {width:10%;}

.cell-sub {margin-right:6px; }
.cell-total {
    margin-left: 6px;

    span {
        cursor: default;
    }
}
.cell-desc, .cell-cost, .cell-dur {
    margin-left:6px;
    margin-right:6px;
    span {
        cursor:default;
    }
}

.gridrow:hover {
    background-color: rgba(68, 88, 144, 0.3);
}

.sub-plan-grid-header {
    text-align:left;
    color:$accent2;
    background-color:$accent1;
    margin-bottom:1vw;
}

.subscribeCheckbox {
    height: clamp(20px,1.5vw,30px);
    width: clamp(20px,1.5vw,30px);
}

.selectedPlan {
    border: solid 1px black;
    box-shadow: inset 0px 0px 20px 20px rgba(68, 88, 144, 0.3);
}


.planInfoBox {
    display:flex;
    flex-direction:column;
    flex-grow:1;
    border:1px solid black;
    border-collapse:collapse;

}
.planSelector {
    font-size: clamp(20px,2vw,40px) !important;
}
.pricing-grid-collapse {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

@include media-breakpoint-down(md) {

    #subPlansTopContainer {
        flex-direction: column;
    }

    #subPlansLeft {
        display: flex;
        justify-content: center;

        img {
            width: 80vw;
            height: auto;
        }
    }


    .cell-sub {
        width: 10%;
    }

    .cell-desc {
        width: 40%;
    }

    .cell-cost {
        width: 15%;
    }

    .cell-dur {
        width: 15%;
    }

    .cell-total {
        width: 15%;
    }

    .cell-sub {
        margin-right: 6px;
    }

    .cell-total {
        margin-left: 6px;
    }

    .cell-desc .cell-cost, .cell-dur {
        margin-left: 6px;
        margin-right: 6px;
    }

    .pricing-grid-collapse {
        flex-direction:column;
    }
}

/* --------------------------------------- 
# Subscription Confirmation
--------------------------------------- */

.green-border {
    border: solid 2px lightgreen !important;
    border-radius: 3px;
    margin-right: 7vw;
}
.trans-border {
    border: solid 4px transparent !important;
}

.rm-fallback {
    margin-right: 13vw;
    margin-left: 13vw;
}

@include media-breakpoint-down(md) {

    .rm-fallback {
        margin-right: 3vw;
        margin-left: 3vw;
    }
}

/* --------------------------------------- 
# Contact us
--------------------------------------- */
#form-intro {
    color:$accent5;
    background-color: $accent2;

}

/* --------------------------------------- 
# Documentation
--------------------------------------- */
.faq-header {
    background-color: $accent2 !important;
    color:$accent5;
    cursor:pointer;
}
.card-body a {
    color: $accent2 !important;

}

.section-grid {
    display:flex;
    flex-direction:row;
}

.section-header {
    background-color: $accent1;
    color: $accent2;
}
.section-body {
    background-color: $accent4;
    color:$accent5;

    
}
.section-body > p > a {
    color: $accent2 !important;
}

@include media-breakpoint-down(md) {

    .section-grid {
        flex-direction: column;
    }

    #fred-widget {
        display:flex;
        justify-content:center;
        margin-bottom:2vw;
    }
}

/* --------------------------------------- 
# Downloads
--------------------------------------- */

.appList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2vw;
    padding-left:1vw;
    padding-right:1vw;
}

.app {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.appImages {
    width: 30vw;
    min-width: 400px;
}

.appDescription {
    margin-left:2vw;
    display:flex;
    flex-direction:column;
    width:70vw;
}
.appImage {
    width: 30vw;
    min-width:400px;
}

.downloadButton {
    
    display: flex;
    flex-direction: column;
    width:30vw;
    align-self:center;
}

@media (max-width: 767px) {
    .app {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .downloadButton {
        display: flex;
        flex-direction: column;
        width: 90vw;
        align-self: center;
    }
}

    /* --------------------------------------- 
# Footer 
    --------------------------------------- */
    .separator {
        background-color: black;
        height: 3px;
        margin: 0;
    }

    #footer-content {
        display: flex;
        flex-flow: column;
        padding: 2vw;
    }


    .footer-row {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .footer-cell {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        margin: 10px 0px 0px 0px;
        flex-direction: column;

        span {
            margin-left: 4px;
        }

        a, img, svg, span {
            margin-right: 0px;
            color: $accent2;
            fill: $accent2
        }

        a:hover, span:hover, svg:hover {
            color: $accent4;
            color: $accent4;
            text-decoration: none;
        }
    }

    .grow-align-right {
        text-align: right;
    }


    .txt {
        color: white;
    }

    .blend {
        color: #330;
    }

    @media (max-width: 767px) {
        .footer-row {
            flex-direction: column;
            align-items: unset;
        }

        .footer-cell {
            flex-direction: row;
            justify-content: space-around;
        }

        .grow-align-right {
            margin-top: 2vw;
        }

        .grow-align-right, .grow-align-right p {
            text-align: center;
        }
    }

    /* --------------------------------------- 
# Modal 
    --------------------------------------- */
    .modal-container {
        box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 1.0);
        border: 3px $modal-accent1 solid;
        border-radius: calc(0.3rem - 1px);
        background-color: $modal-accent2;
    }

    .modal-header {
        background-color: $modal-accent2;
        color: $modal-accent3;

        svg {
            margin-right: 6px;
        }
    }

    .modal-body {
        background-color: $modal-accent2;
        color: $modal-accent5;
    }

    .modal-footer {
        background-color: $modal-accent2;
        color: $modal-accent5;
        border: none !important;
    }

    /* --------------------------------------- 
# Slide 
    --------------------------------------- */
    .each-slide-effect > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 80vh;
        width: 80vw !important;
    }

    .each-slide-effect span {
        padding: 0px;
        font-size: 20px;
        background: #efefef;
        text-align: center;
    }

    .slideshow-container {
        width: 85vw !important;
        max-width: 85vw !important;
    }


    /* ---------- Relative layout  ---------- */

    .rh1 {
        font-size: clamp(40px,4vw,80px);
    }

    .rh2 {
        font-size: clamp(35px,3.5vw,70px);
    }

    .rh3 {
        font-size: clamp(30px,3vw,60px);
    }

    .rh4 {
        font-size: clamp(25px,2.5vw,50px);
    }

    .rh5 {
        font-size: clamp(20px,2vw,40px);
    }

    .rh6 {
        font-size: clamp(15px,1.1vw,30px);
    }

    .rh7 {
        font-size: clamp(13px,.9vw,26px);
    }

    .rp {
        font-size: clamp(12px,1vw,18px);
        line-height: 1.5;
    }

    .rm1 {
        margin: 1vw;
    }

    .rm2 {
        margin: 2vw;
    }

    .rm3 {
        margin: 3vw;
    }

    .rm5 {
        margin: 5vw;
    }

    .rm8 {
        margin: 8vw;
    }

    .rm13 {
        margin: 13vw;
    }

    .rmt1 {
        margin-top: 1vw;
    }

    .rmt2 {
        margin-top: 2vw;
    }

    .rmt3 {
        margin-top: 3vw;
    }

    .rmt5 {
        margin-top: 5vw;
    }

    .rmt8 {
        margin-top: 8vw;
    }

    .rmt13 {
        margin-top: 13vw;
    }

    .rmr1 {
        margin-right: 1vw;
    }

    .rmr2 {
        margin-right: 2vw;
    }

    .rmr3 {
        margin-right: 3vw;
    }

    .rmr5 {
        margin-right: 5vw;
    }

    .rmr8 {
        margin-right: 8vw;
    }

    .rmr13 {
        margin-right: 13vw;
    }

    .rmb1 {
        margin-bottom: 1vw;
    }

    .rmb2 {
        margin-bottom: 2vw;
    }

    .rmb3 {
        margin-bottom: 3vw;
    }

    .rmb5 {
        margin-bottom: 5vw;
    }

    .rmb8 {
        margin-bottom: 8vw;
    }

    .rmb13 {
        margin-bottom: 13vw;
    }

    .rml1 {
        margin-left: 1vw;
    }

    .rml2 {
        margin-left: 2vw;
    }

    .rml3 {
        margin-left: 3vw;
    }

    .rml5 {
        margin-left: 5vw;
    }

    .rml8 {
        margin-left: 8vw;
    }

    .rml13 {
        margin-left: 13vw;
    }


    .rp05 {
        padding: .5vw;
    }

    .rp1 {
        padding: 1vw;
    }

    .rp2 {
        padding: 2vw;
    }

    .rp3 {
        padding: 3vw;
    }

    .rp5 {
        padding: 5vw;
    }

    .rp8 {
        padding: 8vw;
    }

    .rp13 {
        padding: 13vw;
    }

    .rpt1 {
        padding-top: 1vw;
    }

    .rpt2 {
        padding-top: 2vw;
    }

    .rpt3 {
        padding-top: 3vw;
    }

    .rpt5 {
        padding-top: 5vw;
    }

    .rpt8 {
        padding-top: 8vw;
    }

    .rpt13 {
        padding-top: 13vw;
    }

    .rpr1 {
        padding-right: 1vw;
    }

    .rpr2 {
        padding-right: 2vw;
    }

    .rpr3 {
        padding-right: 3vw;
    }

    .rpr5 {
        padding-right: 5vw;
    }

    .rpr8 {
        padding-right: 8vw;
    }

    .rpr13 {
        padding-right: 13vw;
    }

    .rpb1 {
        padding-bottom: 1vw;
    }

    .rpb2 {
        padding-bottom: 2vw;
    }

    .rpb3 {
        padding-bottom: 3vw;
    }

    .rpb5 {
        padding-bottom: 5vw;
    }

    .rpb8 {
        padding-bottom: 8vw;
    }

    .rpb13 {
        padding-bottom: 13vw;
    }

    .rpl1 {
        padding-left: 1vw;
    }

    .rpl2 {
        padding-left: 2vw;
    }

    .rpl3 {
        padding-left: 3vw;
    }

    .rpl5 {
        padding-left: 5vw;
    }

    .rpl8 {
        padding-left: 8vw;
    }

    .rpl13 {
        padding-left: 13vw;
    }


    /* ---------------------------------------
      # Fonts
  --------------------------------------- */
    @font-face {
        font-family: 'SansMan';
        src: local('entsans'), url("./Assets/fonts/entsans.ttf");
    }


    @import "../node_modules/bootstrap/scss/bootstrap";

